<template>
  <mdb-container>
    <Publicheader activepage="cominwebinaires"></Publicheader>
    <main>
      <mdb-row>
        <mdb-col md="12" class="mb-4 mt-4">
          <mdb-card class="card-image" style="background-image: url(/images/img_avenir.jpg)">
            <div class="text-white text-center py-5 px-4 rgba-stylish-strong">
              <div>
                <h1 class="card-title h1-responsive pt-3 font-bold"><strong>Webinaires à venir</strong></h1>
              </div>
            </div>
          </mdb-card>
        </mdb-col>
      </mdb-row>
      <mdb-row>
        <mdb-col md="6" class="pb-4" v-for="webinar in webinars" :key="webinar.id">
          <Webinartile v-bind:webinar="webinar"></Webinartile>
        </mdb-col>
        <mdb-col v-if="webinars.length === 0">
          <mdb-alert color="info" class="text-center">
            <h2>Aucun webinaire à venir!</h2>
          </mdb-alert>
        </mdb-col>
      </mdb-row>
    </main>
    <Publicfooter></Publicfooter>
  </mdb-container>
</template>

<script>
    import Vue from 'vue';
    import Publicheader from '@/components/Publicheader.vue';
    import Publicfooter from '@/components/Publicfooter.vue';
    import Webinartile from '@/components/Webinartile.vue';

    import {
        mdbContainer,
        mdbRow,
        mdbCol,
        mdbCard,
        mdbAlert,
    } from 'mdbvue';

    import Apicall from '../libs/Apicall';

    export default Vue.extend({
        name: 'Home',
        components: {
            Publicheader,
            Publicfooter,
            Webinartile,
            mdbContainer,
            mdbRow,
            mdbCol,
            mdbCard,
            mdbAlert,
        },
        computed: {
            rowCount() {
                return Math.floor((this.webinars.length - 1) / 3) + 1;
            },
        },
        data() {
            return {
                webinars: [],
                errormsg: '',
            };
        },
        mounted() {
            const self = this;
            this.$store.watch(() => this.$store.state.agentcontext, () => { self.reloadWithChangedContext(); });
            this.$store.watch(() => this.$store.state.agencycontext, () => { self.reloadWithChangedContext(); });
            this.loadAndDisplayNextsWebinars();
        },
        methods: {
            reloadWithChangedContext() {
                this.loadAndDisplayNextsWebinars();
            },
            loadAndDisplayNextsWebinars() {
                const self = this;
                const api = new Apicall();
                const query = {
                    agencycontext: this.$store.state.agencycontext,
                    agentcontext: this.$store.state.agentcontext,
                    filter: 'FUTURONLY',
                    page: 1,
                    nbPerPage: 999,
                };
                api.loadWebinars(query).then((response) => {
                    if (response.status === 'OK') {
                        self.webinars = response.data.webinars;
                    } else {
                        self.errormsg = response.msg;
                    }
                });
            },
        },
    });
</script>
